<template>
  <nav :class="$style['footer-nav']">
    <div :class="$style['footer-nav-column']">
      <NavLink :to="referralLand">{{ t("footer.referral_landing") }}</NavLink>
      <NavLink :to="faq">FAQ</NavLink>
      <NavLink :to="contacts">{{ t("footer.contacts") }}</NavLink>
      <NavLink :to="cooperate">{{ t("footer.cooperation") }}</NavLink>
      <NavLink
        :to="privacyPolicy"
        :class="$style['legal-link']"
      >
        {{ t("footer.privacy_policy") }}
      </NavLink>
      <NavLink
        :to="termsAndConditions"
        :class="$style['legal-link']"
      >
        {{ t("footer.user_agreement") }}
      </NavLink>
    </div>
    <div :class="[$style['footer-nav-column'], $style['social-links']]">
      <ClientOnly>
        <a
          target="__blank"
          :href="coreStore.vk"
        >
          {{ t("footer.vk") }}
        </a>
        <a
          target="__blank"
          :href="settings?.platform.tg"
        >
          {{ t("footer.tg") }}
        </a>
        <a
          target="__blank"
          :href="coreStore.discord"
        >
          {{ t("footer.discord") }}
        </a>
      </ClientOnly>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { normalize } from "~/utils/url/normalize";
const { t } = useI18n();
const coreStore = useCoreStore();
const settings = inject<{
  platform: ReturnType<typeof usePlatform>;
}>("settings");

const referralLand = computed(() =>
  normalize(`${settings?.platform.basePath || ""}/referral-land/`),
);

const faq = computed(() =>
  normalize(`${settings?.platform.basePath || ""}/faq/`),
);

const contacts = computed(() =>
  normalize(`${settings?.platform.basePath || ""}/contacts/`),
);
const cooperate = computed(() =>
  normalize(`${settings?.platform.basePath || ""}/faq/cooperate/`),
);
const privacyPolicy = computed(() =>
  normalize(`${settings?.platform.basePath || ""}/privacy-policy/`),
);
const termsAndConditions = computed(() =>
  normalize(`${settings?.platform.basePath || ""}/terms-and-conditions/`),
);
</script>

<style lang="scss" module>
.footer-nav {
  .footer-nav-column {
    display: flex;
    gap: 48px;
  }

  .legal-link,
  .social-links {
    display: none;
  }
  a {
    color: var(--general-grey);
    text-decoration: none;

    &:hover {
      color: var(--general-grey);
      filter: brightness(130%);
    }
    &:active {
      color: var(--general-grey);
      filter: brightness(300%);
    }
  }
  @media screen and (max-width: 1360px) {
    display: flex;
    justify-content: space-between;

    .legal-link {
      display: inline;
    }
    .footer-nav-column {
      min-width: 140px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}
</style>
