<template>
  <PopupOverlay>
    <Popup
      :class="$style.popup"
      :closable="true"
    >
      <div :class="$style.header">
        {{ t("platform.welcome") }}
      </div>
      <div :class="$style.caption">
        {{ t("platform.what_game") }}
      </div>
      <PlatformItems
        :class="$style.items"
        size="md"
        is-selectable
      />
    </Popup>
  </PopupOverlay>
</template>

<script setup lang="ts">
import PopupOverlay from "./PopupOverlay.vue";
import Popup from "./Popup.vue";
import PlatformItems from "./PlatformItems.vue";

const { t } = useI18n();
</script>

<style module lang="scss">
.popup {
  max-width: 750px;
  width: 100%;

  @media screen and (max-width: 475px) {
    max-width: 384px;
    margin: 0 20px;
  }
}

.header {
  font-size: 24px;
  line-height: 28px;
  padding-right: 30px;
}

.caption {
  font-size: 16px;
  margin-top: 12px;
  line-height: 16px;
}

.items {
  margin-top: 24px;
}

.button {
  margin-top: 24px;
}
</style>
