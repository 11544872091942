<template>
  <nav
    :class="$style['desktop-nav']"
    data-test="desktop-nav"
  >
    <NavLink
      :to="casesPath"
      :consider-platform="false"
    >
      <IconCases /> {{ $t("header.cases") }}
    </NavLink>
    <NavLink
      :to="miniGamesPath"
      :consider-platform="false"
    >
      <IconGames />{{ $t("header.games") }}
    </NavLink>
    <NavLink
      v-if="bullpassIsAvailable"
      :to="eventPath"
      :consider-platform="false"
    >
      <IconEvent />Bullpass
    </NavLink>
    <a
      v-if="isGenshin"
      :href="coreStore.gamelight"
    >
      <IconGem /> {{ $t("shop.buy_gems") }}
    </a>
    <NavLink
      v-if="bossBattleEnabled"
      :to="bossBattlePath"
      :consider-platform="false"
    >
      <IconBossBattle />
      {{ t(`${BATTLE_KEY}.title`) }}
    </NavLink>
    <NavLink
      v-if="exchangerEventEnabled"
      :to="exchangerEventPath"
      :consider-platform="false"
    >
      <IconMerlin />
      {{ t(`header.merlin`) }}
    </NavLink>
    <a
      v-if="settings?.platform.is.pubg"
      :href="coreStore.gamelightPubg"
    >
      <IconGold />{{ t("header.buy_uc_cheap") }}
    </a>
  </nav>
</template>

<script setup lang="ts">
import { normalize } from "~/utils/url/normalize";
import { EVENT_NAME } from "~/stores/exchanger";
import { BATTLE_KEY } from "~/components/BossBattle/composables/initMainView";

const { t } = useI18n();
const settings = inject<{
  platform: ReturnType<typeof usePlatform>;
}>("settings");

const coreStore = useCoreStore();

const isGenshin = computed(() => settings?.platform.is.genshin);

const casesPath = computed(() =>
  normalize(`${settings?.platform.basePath || ""}/`),
);

const miniGamesPath = computed(() =>
  normalize(`${settings?.platform.basePath || ""}/mini-games`),
);

const eventPath = computed(() =>
  normalize(`${settings?.platform.basePath || ""}/event`),
);

const exchangerEventPath = computed(() =>
  normalize(`${settings?.platform.basePath || ""}/${EVENT_NAME}`),
);

const bossBattlePath = computed(() =>
  normalize(`${settings?.platform.basePath || ""}/boss-battle`),
);

const exchangerEventEnabled = isDev() ? true : useFlag("exchanger_event");
const bossBattleEnabled = isDev() ? true : useFlag("boss_battle");
const bullpassIsEnabled = useFlag("Bullpass");
const bullpassIsAvailable = computed(() => {
  const platform = usePlatform();
  if (
    bullpassIsEnabled.value &&
    platform.code !== "genshin" &&
    platform.code !== "roblox"
  ) {
    return true;
  } else {
    return false;
  }
});
</script>

<style lang="scss" scoped>
.router-link-exact-active {
  color: var(--general-white);
  background-color: var(--button-ghost-active);

  svg {
    fill: var(--general-white);
  }
}
</style>

<style lang="scss" module>
.desktop-nav {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;

  a,
  button,
  svg {
    transition: 0.25s;
  }

  a,
  button {
    margin: 0 6px;
    padding: 0 16px;
    font-size: 0.875rem;
    color: $white-3;
    letter-spacing: 0.01em;
    font-weight: 500;
    border-radius: 10px;
    color: var(--general-grey);
    height: 40px;

    svg {
      flex-shrink: 0;
      fill: var(--general-grey);
    }

    &:not(.router-link-exact-active):hover {
      background-color: var(--button-ghost-hover);

      svg {
        fill: var(--general-grey);
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  button {
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
  }

  svg {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    fill: $white-3;
  }
}
</style>
